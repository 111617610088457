<template>
  <table class="table is-hoverable is-fullwidth result-table">
    <thead>
    <tr>
      <th>ISBN</th>
      <th>Pakbon</th>
      <th>Titel</th>
      <th>Publicatiedatum</th>
      <th>Status</th>
      <th>Perceel</th>
      <th>Besteld</th>
      <th>Geleverd</th>
      <th>Gescand</th>
      <th>Retour</th>
    </tr>
    </thead>
    <tbody>
      <tr class="result-table__item" v-if="!resultsFromRoute()">
        <td colspan="9">Geen gegevens gevonden.</td>
      </tr>
      <tr v-for="book in resultsFromRoute()" v-bind:key="book" class="result-table__item" @click="goToDetail(book)">
        <td>{{ book['barcode'] }}</td>
        <td class="url--wrapper" v-if="book['packing_slip']">
          <a @click="goToPackingSlip(book['packing_slip_uuid'])" class="url" >
            {{ book['packing_slip'] }}
          </a>
        </td>
        <td v-else>/</td>
        <td>{{ book['product_name'] }}</td>
        <td>{{ book['release_date'] }}</td>
        <td>{{ getPackageSlipStatus(book['status']) }}</td>
        <td>{{ book['customer_parcel'] }}</td>
        <td>{{ book['ordered'] }}</td>
        <td>{{ book['delivered'] }}</td>
        <td>{{ book['scanned'] }}</td>
        <td>{{ book['returned'] }}</td>
      </tr>
      <tr v-if="resultsFromRoute()">
        <td colspan="7"></td>
        <td>{{ totals['delivered'] }}</td>
        <td>{{ totals['scanned'] }}</td>
        <td>{{ totals['returned'] }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {getStatus} from "@/utils/utils";

export default {
  name: "BooksResultTable",
  props: {
    data: Array
  },
  data() {
    return {
      resultGroupsStatus: {},
      totals: {}
    }
  },
  methods: {
    getPackageSlipStatus(status) {
      return getStatus(status)
    },
    goToDetail(data) {
      this.$store.commit('setSearchedBook', data);
      this.$router.push({ name: 'books-detail', params: { id: data['uuid'] }})
    },
    goToPackingSlip(id) {
      const url =  process.env.VUE_APP_BASE_URL + 'web?#id=' + id + '&view_type=form&model=stock.reservation&menu_id=295&action=388';
      window.open(url, '_blank');
    },
    resultsFromRoute() {
      if (this.data[0]) {
        let results = [];
        let totalAmounts = {
          'ordered': 0,
          'delivered': 0,
          'scanned': 0,
          'returned': 0
        }

        this.data.forEach(function (result) {
          if (result['lines'].length > 0) {
            result['lines'].forEach(function (book) {
              results.push({
                'barcode': book['barcode'],
                'product_name': book['product_name'],
                'packing_slip': result['name'],
                'release_date': book['release_date'],
                'uuid': book['book_uuid'],
                'ordered': book['so_line_qty'] ?? 0,
                'delivered': book['qty_to_deliver'] ?? 0,
                'scanned': book['qty_confirmed'] ?? 0,
                'returned': book['qty_retour'] ?? 0,
                'status': result['state'],
                'reservation_date': result['reservation_date'],
                'customer_parcel': result['customer_parcel'],
                'sight_sending': result['sight_sending'],
                'packing_slip_uuid': result['uuid'],
              })

              if (book['so_line_qty']) totalAmounts['ordered'] += book['so_line_qty'];
              if (book['qty_to_deliver']) totalAmounts['delivered'] += book['qty_to_deliver'];
              if (book['qty_confirmed']) totalAmounts['scanned'] += book['qty_confirmed'];
              if (book['qty_retour']) totalAmounts['returned'] += book['qty_retour'];
            })
          }
        })

        this.totals = totalAmounts;
        return results;
      }
      else {
        return false;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  border-radius: 5px;

  &__group {
    font-size: 1rem;
    font-weight: 600;

    svg {
      margin-left: .5rem;
    }
  }

  td, th {
    border: solid #e5e5e5;
    border-width: 1px 0;
  }

  th {
    width: 33%;

    &:last-child {
      width: 100%;
    }
  }

  tbody tr {
    cursor: pointer;
  }

  tr:hover {
    a {
      color: $primary;

      path {
        fill: $primary;
      }
    }
  }

  td {
    padding: 1rem;
    font-size: .9rem;

    a {
      color: $black;
      text-decoration: underline;
      svg {
        transition: all .4s ease-in-out;
        margin-left: 1rem;
      }
      path {
        fill: $black;
      }
    }
  }

  th {
    border-top: none;
    padding: 1.5rem 1rem;
    font-weight: 500;
    color: #000000;
  }
}
</style>
